<template>
  <button
    v-bind="$attrs"
    :class="[setStyle, setButtonShape]"
    @mouseenter.capture="setMouseEnter"
    @mouseleave.capture="setMouseLeave"
    @click="setMouseClicked"
    class=" hover:text-white"
  >
    <a
      :href="href"
      class="flex items-center justify-center w-full gap-2"
      download
      target="_blank"
    >
      <div v-if="prefix">
        <img
          :src="`/Icons/${prefixIcon}.webp`"
          :alt="`${prefixIcon}-icon`"
          role="navigation"
          class="pointer-events-none relative"
          lazy="true"
          :class="[prefixIcon.includes('apple') ? 'mb-[4px]' : 'm-0']"
          width="25"
        />
      </div>
      <div class="flex flex-col">
        <span
          v-if="description"
          class="text-start md:text-lg font-thin px-2 xs:text-base"
          >{{ description }}</span
        >
        <span
          v-if="title"
          class="text-center md:text-lg md:font-[500] xs:font-normal xs:text-base"
          >{{ title }}</span
        >
      </div>
      <div v-if="suffix" :class="[locale === 'ar' ? 'rotate_img' : '']">
        <img
          :src="`/Icons/hover-arrow.webp`"
          alt="hover-arrow-arrow"
          role="navigation"
          lazy="true"
          v-if="hovered"
          class="pointer-events-none"
          width="13"
          height="13"
        />
        <img
          :src="`/Icons/${suffixIcon}.webp`"
          :alt="`${suffixIcon}-icon`"
          role="navigation"
          class="pointer-events-none"
          lazy="true"
          width="13"
          height="13"
          v-else
        />
      </div>
    </a>
  </button>
</template>

<script setup>
import { computed, ref } from "vue";

const hovered = ref(false);
const props = defineProps({
  title: {
    type: String,
    default: "",
  },
  href: {
    type: String,
    default: "/",
  },
  description: {
    type: String,
    default: "",
  },
  prefix: {
    type: Boolean,
    default: false,
  },
  suffix: {
    type: Boolean,
    default: false,
  },
  suffixIcon: {
    type: String,
    default: "right",
  },
  prefixIcon: {
    type: String,
    default: "apple",
  },
  raduis: {
    stype: String,
    defult: "rounded",
  },
  shape: {
    type: String,
    default: "",
  },
  locale: {
    type: String,
    default: "en",
  },
});

const setStyle = computed(() => {
  if (props.raduis === "rounded") {
    return "rounded-[10px]";
  } else {
    return "rounded-[10px]";
  }
});

const setButtonShape = computed(() => {
  if (props.shape === "black") {
    return "bg-black text-white w-[250px] !h-[3.1rem] hover:bg-[#6084E0] focus:bg-[#6084E0] focus-visible:bg-[#6084E0]";
  } else if (props.shape === "transparent") {
    return "bg-transparent border border-[#D9D9D9] w-[250px] !h-[3rem] hover:text-[#6084E0] hover:border hover:border-[#6084E0] focus:border-[#6084E0]";
  } else {
    return "border border-[#6084E0] text-[#6084E0] focus:ring focus:bg-[#6084E0] hover:bg-[#6084E0] hover:text-white w-[250px] !h-[3rem] hpver:text-white";
  }
});

const setMouseEnter = () => {
  if (props.shape === "black" || props.shape === "transparent") return;
  hovered.value = true;
};

const setMouseClicked = () => {
  if (props.shape === "black" || props.shape === "transparent") return;
  setTimeout(() => {
    hovered.value = true;
  }, 100);
};

const setMouseLeave = () => {
  if (props.shape === "black" || props.shape === "transparent") return;
  hovered.value = false;
};
</script>

<style>
</style>