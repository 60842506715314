<template>
  <main class="h-full w-full p-0 m-0" :class="mainClass">
    <GIF v-if="loading" />
    <div class="flex flex-col justify-between h-full" v-else>
      <NavBar />
      <RouterView />
      <Footer />
    </div>
  </main>
</template>

<script setup>
import GIF from "@/components/Common/Gif.vue";
import NavBar from "@/components/Common/Navbar.vue";
import Footer from '@/components/Common/Footer.vue'
import { computed, onBeforeMount, provide, ref } from "vue";
import { RouterView, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

const { locale } = useI18n();

const userAgent = ref("");
const loading = ref(true);
const router = useRouter();

const detectUserAgent = () => {
  const isAndroid = /Android/i.test(navigator.userAgent);
  const isiOS = /(iPhone|iPad|iPod)/i.test(navigator.userAgent);
  const isMac = /(Mac)/i.test(navigator.userAgent);
  const isWindow = /(Window)/i.test(navigator.userAgent);
  const isLinux = /(Linux)/i.test(navigator.userAgent);

  if (isAndroid) {
    userAgent.value = "android";
  } else if (isiOS) {
    userAgent.value = "apple";
  } else if (isMac) {
    userAgent.value = "mac";
  } else if (isWindow) {
    userAgent.value = "window";
  } else if (isLinux) {
    userAgent.value = "linux";
  } else {
    userAgent.value = "";
  }
  provide("userAgent", userAgent.value);
};

onBeforeMount(() => {
  detectUserAgent();
  loading.value = false;
});

const mainClass = computed(() => {
  return locale.value === "ar" ? "" : "roboto";
});
</script>

<style>
.roboto {
  background-color: #fff;
  font-family: "Roboto", sans-serif;
}
</style>