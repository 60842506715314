<template>
  <div class="slider tab:hidden xs:block relative" dir="ltr">
    <swiper
      :slidesPerView="1.3"
      :modules="modules"
      :centeredSlides="true"
      :initialSlide="0"
      :loop="true"
      @swiper="onSwiper"
      @slideChange="updatePagination"
      :autoplay="{
        delay: 2000,
        disableOnInteraction: false,
      }"
      ref="mySwiper"
    >
      <swiper-slide
        v-for="(card, index) in cards"
        :key="index"
        class="swiper-slide p-[6px]"
      >
        <Card
          :image="card.image"
          :position="card.position"
          :overlay="card.overlay"
        />
      </swiper-slide>
    </swiper>
    <div class="custom-pagination">
      <span
        v-for="(_, index) in cards.slice(0, 3)"
        :key="index"
        class="pagination-dot"
        :class="{ active: activeDotIndex === index }"
      >
      </span>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import Card from "./Card.vue";

export default {
  name: "CardSlider",
  components: { Card, Swiper, SwiperSlide },
  props: {
    cards: {
      type: Array,
      default: (() => {[]})
    }
  },
  data() {
    return {
      modules: [Pagination, Navigation, Autoplay],
      currentIndex: 2,
      realIndex: 0,
    };
  },
  computed: {
    activeDotIndex() {
      if (this.realIndex === 0) {
        return 0;
      } else if (this.realIndex === 1) {
        return 1;
      } else if (this.realIndex === 2) {
        return 2;
      } else if (this.realIndex === 3) {
        return 0;
      } else if (this.realIndex === 4) {
        return 1;
      } else if (this.realIndex === 5) {
        return 2;
      } else {
        return 0;
      }
    },
  },
  methods: {
    onSwiper(swiper) {
      this.$refs.mySwiper = swiper;
    },
    updatePagination(swipe) {
      this.realIndex = swipe.realIndex;
    },
  },
};
</script>

<style>
.slider {
  position: relative;
}

.custom-pagination {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
}

.pagination-dot {
  width: 8px;
  height: 8px;
  background-color: #ddd;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.pagination-dot.active {
  background-color: rgba(50, 50, 50, 0.5) !important;
}
</style>
