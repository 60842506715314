import { createI18n } from "vue-i18n";

const numberFormats = {
  "en-US": {
    currency: {
      style: "currency",
      currency: "USD",
    },
  },
  ar: {
    currency: {
      style: "currency",
      currency: "AED",
    },
  },
};

function loadLocaleMessages() {
  const locales = import.meta.glob("./locales/*.json", { eager: true });
  const messages = {};
  for (const path in locales) {
    const matched = path.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales[path].default;
    }
  }
  return messages;
}

export const i18n = createI18n({
  locale: import.meta.env.VITE_APP_I18N_LOCALE || "en-US",
  fallbackLocale: import.meta.env.VITE_APP_I18N_FALLBACK_LOCALE || "en-US",
  messages: loadLocaleMessages(),
  numberFormats,
  legacy: false,
});
