import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/HomeView.vue";
import Feature from "@/views/FeatureView.vue";
import Download from "@//views/Download.vue";
import Security from "@//views/Security.vue";
import Privacy from "@/views/Privacy.vue";
import Terms from "@/views/Terms.vue";
const NotFound = () => import("../views/NotFound.vue");

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      meta: {
        breadcrumb: "Home",
      },
      component: Home,
    },
    {
      path: "/feature",
      name: "feature",
      meta: {
        breadcrumb: "feature",
      },
      component: Feature,
    },
    {
      path: "/download",
      name: "download",
      meta: {
        breadcrumb: "download",
      },
      component: Download,
    },
    {
      path: "/end-to-end-encryption",
      name: "security",
      meta: {
        breadcrumb: "security",
      },
      component: Security,
    },
    {
      path: "/privacy-policy",
      name: "privacy-policy",
      meta: {
        breadcrumb: "privacy-policy",
      },
      component: Privacy,
    },
    {
      path: "/terms-of-use",
      name: "terms-of-use",
      meta: {
        breadcrumb: "terms-of-use",
      },
      component: Terms,
    },
    { path: "/:pathMatch(.*)*", name: "NotFound", component: NotFound },
  ],
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
