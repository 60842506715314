<template>
  <section class="bg-white" :class="[isShow ? '' : 'only_for_tablets']">
    <div
      class="md:flex items-center md:flex-row xs:flex-col w-full h-full gap-8"
    >
      <DownloadCard
        :device="$t('mobile')"
        image="Samsung"
        v-if="userAgent == 'android'"
      >
        <template #slot>
          <div class="py-4">
            <img
              src="/Images/Samsung.webp"
              alt="Download JERNAS for Samsung Device"
              class="pointer-events-none md:h-full xs:h-[400px]"
            />
          </div>
          <div class="flex items-center justify-center flex-col gap-6 mt-4 md:mb-4 xs:mb-8" v-if="userAgent !== 'window'">
            <Button
              :title="$t('download_for_android')"
              shape="transparent"
              prefix
              prefixIcon="white-andriod"
              :href="donwloadlinks.andriod"
              class="text-white !bg-[#6084E0] border !border-[#6084E0] h-12"
            />
          </div>
        </template>
      </DownloadCard>

      <DownloadCard
        :device="$t('mobile')"
        image="Samsung"
        active
        v-if="userAgent == 'mac'"
      >
        <template #slot>
          <div class="py-4">
            <img
              src="/Images/iphone.webp"
              alt="Download JERNAS for Apple Device"
              lazy="true"
              fetchpriority="low"
              class="pointer-events-none md:h-full xs:h-[400px]"
            />
          </div>
          <div class="flex items-center justify-center flex-col gap-6 mt-4 md:mb-4 xs:mb-8">
            <Button
              :title="$t('download_for_ios')"
              shape="transparent"
              prefix
              prefixIcon="white-apple"
              :href="donwloadlinks.ios"
              class="text-white !bg-[#6084E0] border !border-[#6084E0] h-12"
            />
          </div>
        </template>
      </DownloadCard>

      <DownloadCard
        :device="$t('mobile')"
        image="Samsung"
        active
        v-if="userAgent == 'apple'"
      >
        <template #slot>
          <div class="py-4">
            <img
              src="/Images/iphone.webp"
              alt="Download JERNAS for Apple Device"
              class="pointer-events-none md:h-full xs:h-[400px]"
            />
          </div>
          <div class="flex items-center justify-center flex-col gap-6 mt-4 md:mb-4 xs:mb-8">
            <Button
              :title="$t('download_for_ios')"
              shape="transparent"
              prefix
              prefixIcon="white-apple"
              :href="donwloadlinks.ios"
              class="text-white !bg-[#6084E0] border !border-[#6084E0] h-12"
            />
            <p class="text-sm text-[#7B7B7B] font-[300] text-nowrap sm:block xs:hidden">
              {{ $t("visit_jernas") }}
              <span class="text-[#6084E0]"> jernas.io </span>
              {{ $t("install_on_mobile") }}
            </p>
          </div>
        </template>
      </DownloadCard>

      <DownloadCard
        :device="$t('mobile')"
        image="Samsung"
        active
        v-if="userAgent == 'window'"
      >
        <template #slot>
          <div class="py-4">
            <img
              src="/Images/iphone.webp"
              alt="Download JERNAS for Apple Device"
              class="pointer-events-none"
            />
          </div>
          <div class="flex flex-col gap-3 my-4">
            <p class="text-lg font-semibold text-[#7B7B7B] mx-6">
              Not available for this device
            </p>
            <p class="text-sm text-[#7B7B7B] font-[300] text-nowrap">
              {{ $t("visit_jernas") }}
              <span class="text-[#6084E0]"> jernas.io </span>
              {{ $t("install_on_mobile") }}
            </p>
          </div>
        </template>
      </DownloadCard>
    </div>
  </section>
</template>
  
  <script setup>
import { inject, onMounted, ref } from "vue";
import { DownloadCard, Button, Download } from "../reuse";

const userAgent = inject("userAgent");
const donwloadlinks = inject("Download_links");
const isMobile = ref(false);

const props = defineProps({
  isShow: {
    type: Boolean,
    default: false,
  },
});

onMounted(() => {
  if (window.innerWidth <= 640) {
    isMobile.value = true;
  }
});
</script>

<style>
.only_for_tablets {
  display: none;
}

/* iPad Pro */
@media only screen and (device-width: 1024px) and (-webkit-device-pixel-ratio: 2) {
  .not_for_tablets {
    display: none;
  }
  .only_for_tablets {
    display: block;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .not_for_tablets {
    display: none;
  }
  .only_for_tablets {
    display: block;
  }
}
@media only screen and (device-width: 853px) and (device-height: 1280px) {
  .not_for_tablets {
    display: none;
  }
  .only_for_tablets {
    display: block;
  }
}
</style>