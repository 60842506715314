<template>
  <section class="mb-12">
    <!-- banner -->
    <div class="bg-[#020013]">
      <div class="w-full h-full security-container">
        <div class="flex item-center justify-center relative">
          <div class="w-full h-full z-0">
            <img
              src="/Images/security_bg.webp"
              alt="security-logo"
              role="Image for security term"
              aria-label="security terms"
              class="pointer-events-none sm:h-auto xs:h-[200px]"
            />
          </div>
          <div class="absolute w-full h-full z-1">
            <div
              class="flex items-center justify-center flex-col h-full md:px-[10rem] sm:p-[6rem] xs:p-6 text-center gap-3 relative md:top-[-2rem]"
            >
              <picture>
                <img
                  src="/Icons/security_log.webp"
                  alt="protection-logo"
                  role="logo for security term"
                  aria-label="protection terms"
                  class="md:my-8 lg:block xs:hidden pointer-events-none"
                  width="80"
                />
              </picture>

              <h1 class="text-white md:text-4xl sm:text-2xl xs:text-base font-bold text-nowrap">
                {{ $t('end_to_end_encryption') }}
              </h1>
              <p class="text-white md:text-lg sm:text-sm xs:!text-[15px]">
                {{ $t('end_to_end_encryption_description') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- content -->
    <div class="w-full h-full bg-white security-container">
      <article class="md:px-12 md:py-16 sm:px-6 sm:pt-10 xs:pt-10 xs:px-0">
        <h1 class="text-[#0B0B0B] md:text-3xl sm:text-xl xs:text-xl font-bold text-center">
          {{ $t('what_is_end_to_end_encryption') }}
        </h1>
        <div class="flex flex-col md:gap-4 xs:gap-3 md:my-10 md:mx-16 sm:my-8 sm:mx-10 xs:my-6 xs:mx-6">
          <p class=" md:text-base  xs:!text-[15px] text-[#7B7B7B] break-word">
            {{ $t('jernas_usage') }}
            <span class="text-[#0B0B0B] font-semibold break-word"
              >{{ $t('e2e') }}</span
            >
            {{ $t('securing') }}
          </p>
          <p class=" md:text-base  xs:!text-[15px] text-[#7B7B7B] break-word tracking-tight">
            {{$t('end_to_end_encryption_objective')}}
          </p>
          <p class=" md:text-base  xs:!text-[15px] text-[#7B7B7B] break-word tracking-tight">
            <strong class="text-[#0B0B0B] font-semibold"
              >{{ $t('sender') }}</strong
            >
            {{ $t('only_sender_receiver_decrypt_messages') }}
          </p>
          <p class=" md:text-base  xs:!text-[15px] text-[#7B7B7B] break-word tracking-tight">
            {{ $t('jernas_usage') }}
            <strong class="text-[#0B0B0B] font-semibold"
              >{{ $t('sec_codes') }}</strong
            >
            {{ $t('unique_security_codes_usage') }}
          </p>

          <p class="mt-[-1rem]">
            <strong class="text-[#0B0B0B] font-semibold relative top-4 md:text-base  break-word tracking-tight"
              >{{ $t('how_to_verify_end_to_end_encryption') }}</strong
            >
          </p>
          <ol class="text-[#7B7B7B] sm:text-base  xs:!text-[15px] mt-2 break-word tracking-tight">
            <li>1. {{ $t('open_the_chat') }}</li>
            <li>
              2.
              {{ $t('tap_on_contact_name_to_open_contact_info') }}
            </li>
            <li>
              3.
              {{ $t('tap_verify_security_code_to_view_code') }}
            </li>
            <li>4. 
              {{ $t('make_sure_codes_on_both_devices_are_identical') }}
            </li>
          </ol>

          <p class=" md:text-base  xs:!text-[15px] text-[#7B7B7B] break-word tracking-tight">
            {{ $t('sec_p1') }}
          </p>
          <p class=" md:text-base  xs:!text-[15px] text-[#7B7B7B] break-word tracking-tight">
            {{ $t('sec_p2') }}
          </p>

          <p class=" md:text-base  xs:!text-[15px] text-[#7B7B7B] break-word tracking-tight">
            {{ $t('sec_p3') }}
          </p>
        </div>
      </article>
    </div>
  </section>
</template>

<style>

</style>