<template>
  <header class="w-full mx-auto bg-white sm:sticky sm:top-0 z-50">
    <nav
      class="flex items-center justify-between py-2 xl:px-4 flex-1 relative container"
    >
      <div class="w-full">
        <RouterLink to="/" @click.prevent="closeNavbar">
          <picture>
            <source
              media="(min-width: 768px)"
              srcset="/Images/logo_big.webp"
              type="image/webp"
            />
            <source
              media="(max-width: 767px)"
              srcset="/Images/logo_big.webp"
              type="image/webp"
            />
            <img
              src="/Images/logo.webp"
              alt="jernas-logo"
              loading="lazy"
              importance="high"
              role="logo"
              class="pointer-events-none"
              width="189"
            />
          </picture>
        </RouterLink>
      </div>
      <ul
        class="items-centers justify-center w-full gap-12 xl:flex lg:flex md:hidden sm:hidden xs:hidden"
      >
        <li v-for="(item, index) in Navlinks" :key="index">
          <RouterLink
            :to="item.path"
            @click.prevent="setPath(item.path)"
            :class="[
               activeRoute === item.path
                ? 'text-[#6084E0]'
                : 'text-[#0B0B0B]',
            ]"
            class="hover:text-[#6084E0]"
            >{{ item.name }}</RouterLink
          >
        </li>
        <li>
          <Dropdown
            :label="language"
            :Options="Options"
            @selected="selectLang"
          />
        </li>
      </ul>

      <!-- for small screen -->
      <div class="flex gap-6">
        <div class="w-fit" v-if="hamburger" @click.capture="openNavbar">
          <img
            src="/Icons/collaspe.webp"
            alt="Collapsed menu"
            class="xl:hidden lg:hidden md:block sm:block xs:block cursor-pointer w-[26px] h-[26px] pointer-events-none"
            aria-label="Toggle navigation menu"
          />
        </div>
        <img
          v-else
          src="/Icons/Close.webp"
          alt="Close menu"
          class="xl:hidden lg:hidden md:block sm:block xs:block cursor-pointer w-[26px] h-[26px]"
          aria-label="Toggle navigation menu"
          @click.prevent="closeNavbar"
        />
      </div>
    </nav>
    <div
      class="xl:hidden lg:hidden w-full h-full !bg-white top-18 z-3 text-white z-[999] absolute"
      v-if="isActiveBar"
    >
      <div class="container bg-white">
        <div
          v-for="(item, index) in Navlinks"
          :key="index"
          @click.prevent="setPath(item.path)"
          class="w-full p-6 hover:bg-[#E6EDFF] bg-white"
          :class="[
            activeRoute === item.path ? 'text-[#6084E0]' : 'text-[#0B0B0B]',
          ]"
        >
          <div
            :class="[item.isDropdown ? 'flex item-center justify-between' : '']"
          >
            <RouterLink :to="item.path" class="hover:text-[#6084E0]">{{
              item.name
            }}</RouterLink>
          </div>
        </div>
      </div>
      <div class="container bg-white" @click="openLanguageDialog()">
        <div
          class="flex p-6 items-center justify-between text-black hover:bg-[#E6EDFF]"
        >
          <a class="hover:text-[#6084E0]">{{ language }}</a>
          <img
            src="/Icons/chevron_right.webp"
            alt="Collapsed menu"
            aria-label="close navigation menu"
            width="30"
            height="30"
            class="pointer-events-none"
            :class="[locale === 'ar' ? 'rotate_img' : '']"
          />
        </div>
      </div>
    </div>
    <Dialog :showDialog="showDialog" @close="closeDialog">
      <template #content>
        <div
          class="hover:bg-[#F5F5F5] w-full hover:rounded-[5px]"
          v-for="item in Options"
          :key="item.language"
        >
          <div
            class="flex items-center justify-between gap-6 px-6 py-3"
            @click.prevent="selectLang(item.language)"
          >
            <span class="font-normal text-sm">
              {{ item?.language === "En" ? "English" : "العربية" }}
            </span>
            <div
              class="w-4 h-4 border border-gray-400 rounded-full flex items-center justify-center"
            >
              <div
                class="w-[10px] h-[10px] rounded-full"
                :class="[
                  selectedOption === item.language
                    ? 'bg-[#6084E0]'
                    : 'bg-transparent',
                ]"
              />
            </div>
          </div>
        </div>
      </template>
    </Dialog>
  </header>
</template>
  
<script setup>
import {
  computed,
  onBeforeMount,
  onMounted,
  onUnmounted,
  ref,
  watch,
  watchEffect,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { Dialog, Dropdown } from "../reuse";
import { useI18n } from "vue-i18n";

const storedLocale = localStorage.getItem("locale");
const storedDir = localStorage.getItem("dir");
const htmlInstance = document.getElementsByTagName("html")[0];
const router = useRouter();
const route = useRoute();
const userPreference = ref();
const { t, locale } = useI18n();

const Options = [
  { id: 0, language: "En" },
  { id: 1, language: "العربية" },
];

const isActiveBar = ref(false);
const showDialog = ref(false);
const hamburger = ref(true);
const selectedOption = ref("");
const language = ref("En");
const activeRoute = ref("/");

const Navlinks = computed(() => [
  { name: t("home"), path: "/" },
  { name: t("features"), path: "/feature" },
  { name: t("download"), path: "/download" },
  { name: t("security"), path: "/end-to-end-encryption" },
]);

const setPath = (path) => {
  console.log(path, '-path');
  if (path === "") return;
  activeRoute.value = path;
  router.push(activeRoute.value);
  isActiveBar.value = false;
  hamburger.value = true;
};

const openNavbar = () => {
  isActiveBar.value = true;
  hamburger.value = false;
};

const closeNavbar = () => {
  isActiveBar.value = false;
  hamburger.value = true;
};

const openLanguageDialog = () => {
  showDialog.value = true;
};

const closeDialog = () => {
  showDialog.value = false;
};

const selectLang = (lang) => {
  language.value = lang;
  selectedOption.value = lang;
  locale.value = lang === "En" ? "en" : "ar";

  if (locale.value === "ar") {
    htmlInstance.setAttribute("dir", "rtl");
    localStorage.setItem("dir", "rtl");
  } else {
    htmlInstance.setAttribute("dir", "ltr");
    localStorage.setItem("dir", "ltr");
  }
  showDialog.value = false;
  isActiveBar.value = false;
  hamburger.value = true;
};

watch(
  () => route.path,
  (newPath) => {
    activeRoute.value = newPath;
  }
);

watch(
  () => locale.value,
  (newValue) => {
    localStorage.setItem("locale", newValue);
    language.value = newValue === "en" ? "En" : "العربية";
    selectedOption.value = language.value;
  },
  { deep: true }
);

watchEffect(() => {
  if (
    activeRoute.value  == "/privacy-policy" ||
    activeRoute.value  == "/terms-of-use"
  ) {
    activeRoute.value = "";
  }
});

onMounted(() => {
  if (storedLocale) {
    locale.value = storedLocale;
    language.value = storedLocale === "en" ? "En" : "العربية";
    selectedOption.value = language.value;
  } else {
    locale.value = "en";
    language.value = "En";
    selectedOption.value = "En";
    localStorage.setItem("locale", "en");
  }

  if (storedDir) {
    htmlInstance.setAttribute("dir", storedDir);
  } else {
    htmlInstance.setAttribute("dir", "ltr");
    localStorage.setItem("dir", "ltr");
  }
  activeRoute.value = route.path;
});

onBeforeMount(() => {
  userPreference.value = navigator.language.includes("-")
    ? navigator.language.split("-")[0]
    : navigator.language;
  locale.value = userPreference.value;
});

onUnmounted(() => {
  showDialog.value = false;
  hamburger.value = true;
  activeRoute.value = "";
});
</script>
