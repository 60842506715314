<template>
  <section class="bg-white container not_for_tablets">
    <div
      class="md:flex items-center md:flex-row xs:flex-col w-full h-full gap-4 mt-2 md:mb-14 sm:mb-8 xs:mb-4"
    >
      <DownloadCard :device="$t('mobile')" image="Samsung" v-if="!isMobile">
        <template #slot>
          <div class="py-2">
            <img
              src="/Images/Samsung.webp"
              alt="Download JERNAS for Mobile Devices"
              class="pointer-events-none"
              width="200"
              height="380"
            />
          </div>
          <div class="flex flex-col gap-4 my-4" v-if="userAgent !== 'linux'">
            <Button
              :title="$t('download_for_android')"
              shape="transparent"
              prefix
              prefixIcon="andriod-green"
              :href="donwloadlinks.andriod"
            />
            <Button
              :title="$t('download_for_ios')"
              shape="transparent"
              prefix
              prefixIcon="apple_dark"
              :href="donwloadlinks.ios"
            />
            <p
              class="text-xs text-[#7B7B7B] font-[300] md:relative md:left-2 md:top-2"
            >
              {{ $t("visit_jernas") }}
              <RouterLink to="/" class="text-[#6084E0] cursor-pointer">
                jernas.io
              </RouterLink>
              {{ $t("install_on_mobile") }}
            </p>
          </div>
        </template>
      </DownloadCard>

      <DownloadCard :device="$t('mobile')" image="Samsung" v-if="isMobile">
        <template #slot>
          <!-- andriod -->
          <div class="py-12" v-if="userAgent === 'andriod'">
            <img
              src="/Images/Samsung.webp"
              alt="Download JERNAS for Samsung Device"
              class="pointer-events-none"
            />
          </div>
          <div class="flex flex-col gap-6 my-4" v-if="userAgent === 'andriod'">
            <Button
              :title="$t('download_for_android')"
              shape="transparent"
              prefix
              prefixIcon="andriod-green"
              :href="donwloadlinks.andriod"
            />
            <p class="text-sm text-[##7B7B7B] font-normal">
              {{ $t("visit_jernas") }}
              <span class="text-[#6084E0]"> jernas.io </span>
              {{ $t("install_on_mobile") }}
            </p>
          </div>
          <!-- apple -->
          <div class="py-12" v-if="userAgent === 'apple'">
            <img
              src="/Images/iphone.webp"
              alt="Download JERNAS for Apple Device"
              class="pointer-events-none"
            />
          </div>
          <div class="flex flex-col gap-6 my-4" v-if="userAgent === 'apple'">
            <Button
              :title="$t('download_for_ios')"
              shape="transparent"
              prefix
              prefixIcon="apple_dark"
              :href="donwloadlinks.ios"
            />
            <p class="text-sm text-[#7B7B7B] font-[300]">
              {{ $t("visit_jernas") }}
              <span class="text-[#6084E0]"> jernas.io </span>
              {{ $t("install_on_mobile") }}
            </p>
          </div>
        </template>
      </DownloadCard>

      <DownloadCard :device="$t('mac')" image="macbook" active v-if="!isMobile">
        <template #slot>
          <div class="" :class="[userAgent === 'linux' ? 'pt-0 mb-0' : 'pt-8 mb-12']">
            <img
              src="/Images/macbook.webp"
              alt="Samsung-device"
              class="pointer-events-none"
              width="550"
              height="315"
            />
          </div>
          <div class="flex flex-col gap-4" :class="[userAgent === 'linux' ? 'my-12' : 'my-8']">
            <Button
              v-if="userAgent !== 'linux'"
              :title="$t('download_for_macos')"
              shape="black"
              prefix
              prefixIcon="imac"
              :href="donwloadlinks.macOS"
            />
            <div class="py-8" v-if="userAgent !== 'linux'"></div>
          </div>
        </template>
      </DownloadCard>
    </div>
  </section>
</template>

<script setup>
import { inject, onMounted, ref } from "vue";
import { DownloadCard, Button, Download } from "../reuse";

const userAgent = inject("userAgent");
const donwloadlinks = inject("Download_links");

const isMobile = ref(false);

const props = defineProps({
  isShow: {
    type: Boolean,
    default: false
  }
})

onMounted(() => {
  if (window.innerWidth <= 640) {
    isMobile.value = true;
  }
});
</script>