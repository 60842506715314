<template>
  <div class="security-container">
    <div class="w-full h-full">
      <article class="md:px-10 md:py-10 sm:px-6 sm:pt-10 xs:pt-5 xs:px-0">
        <h1
          class="text-[#0B0B0B] md:text-4xl sm:text-lg xs:text-base font-bold text-center"
        >
          {{ $t('terms_of_use_title') }}
        </h1>

        <div
          class="flex flex-col gap-2 md:my-10 md:mx-16 sm:my-8 sm:mx-10 xs:my-6 xs:mx-6"
        >
          <!-- <p class=" md:text-base sm:text-sm xs:text-[15px] break-word tracking-normal font-normal text-[#7B7B7B]">
            {{ $t('terms_of_use_title') }}
          </p> -->
          <p class=" md:text-base sm:text-sm xs:text-[15px] break-word tracking-normal font-normal text-[#7B7B7B]">
            {{ $t('last_updated') }}
          </p>
          <p class=" md:text-base sm:text-sm xs:text-[15px] break-word tracking-normal font-normal text-[#7B7B7B]">
            {{ $t('please_read') }}
          </p>
          <h2 class=" md:text-2xl sm:text-lg xs:text-base font-bold text-[#7B7B7B]">
            {{ $t('interpretation_and_definitions') }}
          </h2>

          <p class=" md:text-xl sm:text-base xs:text-[15px] break-word tracking-normal font-bold text-[#7B7B7B]">
            {{ $t('term_interpretation') }}
          </p>

          <p class=" md:text-base sm:text-sm xs:text-[15px] break-word tracking-normal font-normal text-[#7B7B7B]">
            {{ $t('interpretation_text') }}
          </p>
          <p class=" md:text-xl sm:text-base xs:text-[15px] break-word tracking-normal font-bold text-[#7B7B7B]">
            {{ $t('definitions') }}
          </p>
          <p class=" md:text-base sm:text-sm xs:text-[15px] break-word tracking-normal font-normal text-[#7B7B7B]">
            {{ $t('definitions_text') }}
          </p>
          <ul
            class="text-[#7B7B7B] text-base font-normal px-6 flex flex-col gap-2"
          >
            <li class="list-disc md:text-base xs:text-[15px] break-word tracking-normal">
              {{ $t('application') }}
            </li>
            <li class="list-disc md:text-base xs:text-[15px] break-word tracking-normal">
              {{ $t('application_store') }}
            </li>
            <li class="list-disc md:text-base xs:text-[15px] break-word tracking-normal">
              {{ $t('affiliate') }}
            </li>
            <li class="list-disc md:text-base xs:text-[15px] break-word tracking-normal">{{ $t('country') }}</li>
            <li class="list-disc md:text-base xs:text-[15px] break-word tracking-normal">
              {{ $t('company') }}
            </li>
            <li class="list-disc md:text-base xs:text-[15px] break-word tracking-normal">
              {{ $t('service') }}
            </li>
            <li class="list-disc md:text-base xs:text-[15px] break-word tracking-normal">{{ $t('terms_of_use') }}</li>
            <li class="list-disc md:text-base xs:text-[15px] break-word tracking-normal">
              {{ $t('third_party_social_media_service') }}
            </li>
            <li class="list-disc md:text-base xs:text-[15px] break-word tracking-normal">
              {{ $t('you') }}
            </li>
            <li class="list-disc md:text-base xs:text-[15px] break-word tracking-normal">
              {{ $t('acknowledgment') }}
            </li>
          </ul>

          <h2 class=" md:text-lg sm:text-base xs:text-[15px] break-word tracking-normal font-bold text-[#7B7B7B] my-2">
            {{ $t('acknowledgment_text') }}
          </h2>

          <p class=" md:text-base sm:text-sm xs:text-[15px] break-word tracking-normal font-normal text-[#7B7B7B]">
            {{ $t('access_and_use') }}
          </p>

          <p class=" md:text-base sm:text-sm xs:text-[15px] break-word tracking-normal font-normal text-[#7B7B7B]">
            {{ $t('by_accessing') }}
          </p>
          <p class=" md:text-base sm:text-sm xs:text-[15px] break-word tracking-normal font-normal text-[#7B7B7B]">
            {{ $t('over_age') }}
          </p>

          <p class=" md:text-base sm:text-sm xs:text-[15px] break-word tracking-normal font-normal text-[#7B7B7B]">
            {{ $t('privacy_policy_acceptance') }}
          </p>
          <p class=" md:text-base sm:text-sm xs:text-[15px] break-word tracking-normal font-normal text-[#7B7B7B]">
            {{ $t('links_to_other_websites') }}
          </p>

          <h2 class=" md:text-lg sm:text-base xs:text-[15px] break-word tracking-normal font-bold text-[#7B7B7B] my-2">
            {{ $t('links_to_other_websites_text') }}
          </h2>

          <p class=" md:text-base sm:text-sm xs:text-[15px] break-word tracking-normal font-normal text-[#7B7B7B]">
            {{ $t('no_control') }}
          </p>
          <p class=" md:text-base sm:text-sm xs:text-[15px] break-word tracking-normal font-normal text-[#7B7B7B]">
            {{ $t('advise_read_terms') }}
          </p>
          <p class=" md:text-base sm:text-sm xs:text-[15px] break-word tracking-normal font-normal text-[#7B7B7B]">
            {{ $t('termination') }}
          </p>

          <h2 class=" md:text-lg sm:text-base xs:text-[15px] break-word tracking-normal font-bold text-[#7B7B7B] my-2">
            {{ $t('termination_text') }}
          </h2>
          <p class=" md:text-base sm:text-sm xs:text-[15px] break-word tracking-normal font-normal text-[#7B7B7B]">
            {{ $t('termination_rights') }}
          </p>
          <p class=" md:text-base sm:text-sm xs:text-[15px] break-word tracking-normal font-normal text-[#7B7B7B]">
            {{ $t('limitation_of_liability') }}
          </p>

          <h2 class=" md:text-lg sm:text-base xs:text-[15px] break-word tracking-normal font-bold text-[#7B7B7B] my-2">
            {{ $t('limitation_of_liability_text') }}
          </h2>
          <p class=" md:text-base sm:text-sm xs:text-[15px] break-word tracking-normal font-normal text-[#7B7B7B]">
            {{ $t('limitation_of_liability_text') }}
          </p>
          <p class=" md:text-base sm:text-sm xs:text-[15px] break-word tracking-normal font-normal text-[#7B7B7B]">
            {{ $t('max_extent') }}
          </p>
          <p class=" md:text-base sm:text-sm xs:text-[15px] break-word tracking-normal font-normal text-[#7B7B7B]">
            {{ $t('some_states') }}
          </p>

          <h2 class=" md:text-xl sm:text-base xs:text-[15px] break-word tracking-normal font-bold text-[#7B7B7B] my-2">
            {{ $t('as_is') }}
          </h2>

          <p class=" md:text-base sm:text-sm xs:text-[15px] break-word tracking-normal font-normal text-[#7B7B7B]">
            {{ $t('as_is_text') }}
          </p>
          <p class=" md:text-base sm:text-sm xs:text-[15px] break-word tracking-normal font-normal text-[#7B7B7B]">
            {{ $t('without_limiting') }}
          </p>
          <p class=" md:text-base sm:text-sm xs:text-[15px] break-word tracking-normal font-normal text-[#7B7B7B]">
            {{ $t('some_jurisdictions') }}
          </p>

          <h2 class=" md:text-xl sm:text-base xs:text-[15px] break-word tracking-normal font-bold text-[#7B7B7B] my-2">
            {{ $t('governing_law') }}
          </h2>
          <p class=" md:text-base sm:text-sm xs:text-[15px] break-word tracking-normal font-normal text-[#7B7B7B]">
            {{ $t('governing_law_text') }}
          </p>

          <h2 class=" md:text-xl sm:text-base xs:text-[15px] break-word tracking-normal font-bold text-[#7B7B7B] my-2">
            {{ $t('disputes_resolution') }}
          </h2>
          <p class=" md:text-base sm:text-sm xs:text-[15px] break-word tracking-normal font-normal text-[#7B7B7B]">
            {{ $t('disputes_resolution_text') }}
          </p>

          <h2 class=" md:text-xl sm:text-base xs:text-[15px] break-word tracking-normal font-bold text-[#7B7B7B] my-2">
            {{ $t('eu_users') }}
          </h2>
          <p class=" md:text-base sm:text-sm xs:text-[15px] break-word tracking-normal font-normal text-[#7B7B7B]">
            {{ $t('eu_users_text') }}
          </p>

          <h2 class=" md:text-xl sm:text-base xs:text-[15px] break-word tracking-normal font-bold text-[#7B7B7B] my-2">
            {{ $t('us_legal_compliance') }}
          </h2>
          <p class=" md:text-base sm:text-sm xs:text-[15px] break-word tracking-normal font-normal text-[#7B7B7B]">
            {{ $t('us_legal_compliance_text') }}
          </p>

          <h2 class=" md:text-2xl sm:text-base xs:text-[15px] break-word tracking-normal font-bold text-[#7B7B7B] my-2">
            {{ $t('severability_and_waiver') }}
          </h2>

          <h3 class=" md:text-xl sm:text-base xs:text-[15px] break-word tracking-normal font-bold text-[#7B7B7B]">
            {{ $t('severability') }}
          </h3>
          <p class=" md:text-base sm:text-sm xs:text-[15px] break-word tracking-normal font-normal text-[#7B7B7B]">
            {{ $t('severability_text') }}
          </p>

          <h3 class=" md:text-xl sm:text-base xs:text-[15px] break-word tracking-normal font-bold text-[#7B7B7B]">
            {{ $t('waiver') }}
          </h3>

          <p class=" md:text-base sm:text-sm xs:text-[15px] break-word tracking-normal font-normal text-[#7B7B7B]">
            {{ $t('waiver_text') }}
          </p>

          <h2 class=" md:text-xl sm:text-base xs:text-[15px] break-word tracking-normal font-bold text-[#7B7B7B] my-2">
            {{ $t('translation_interpretation') }}
          </h2>
          <p class=" md:text-base sm:text-sm xs:text-[15px] break-word tracking-normal font-normal text-[#7B7B7B]">
            {{ $t('translation_interpretation_text') }}
          </p>

          <h2 class=" md:text-xl sm:text-base xs:text-[15px] break-word tracking-normal font-bold text-[#7B7B7B] my-2">
            {{ $t('changes_to_these_terms_of_use') }}
          </h2>
          <p class=" md:text-base sm:text-sm xs:text-[15px] break-word tracking-normal font-normal text-[#7B7B7B]">
            {{ $t('changes_to_these_terms_of_use_text') }}
          </p>
          <p class=" md:text-base sm:text-sm xs:text-[15px] break-word tracking-normal font-normal text-[#7B7B7B]">
            {{ $t('continuing_to_access') }}
          </p>

          <h2 class=" md:text-xl sm:text-base xs:text-[15px] break-word tracking-normal font-bold text-[#7B7B7B] my-2">
            {{ $t('term_contact_us') }}
          </h2>
          <p class=" md:text-base sm:text-sm xs:text-[15px] break-word tracking-normal font-normal text-[#7B7B7B]">
            {{ $t('contact_us_text') }}
          </p>
          <ul class="text-[#7B7B7B] text-base font-normal px-6 flex flex-col gap-2">
            <li class="list-disc md:text-base xs:text-[15px] break-word tracking-normal">{{ $t('contact_us_by_email') }}help@jernas.io</li>
          </ul>
        </div>
      </article>
    </div>
  </div>
</template>
  
<script>
export default {};
</script>
  
<style>
</style>