<template>
  <section class="hero-bg">
    <div class="w-full h-full">
      <div class="container">
        <div
          class="flex md:flex-row xs:flex-col item-center justify-between h-full w-full md:pt-8 not_for_tablets"
        >
          <div class="md:block xs:hidden w-full">
            <div class="grid content-center w-full h-full xl:gap-28 lg:gap-0">
              <div class="w-full h-full flex flex-col gap-2">
                <h1 class="text-[2.9rem] font-bold hero_title">
                  {{ $t("hero_title") }}
                </h1>
                <h4
                  class="lg:text-xl xs:text-base font-normal text-[#4B4B4B] tracking-[0.72px] hero_subtitle"
                >
                  {{ $t("hero_subtitle") }}
                </h4>
              </div>
              <div
                class="w-full flex relative bottom-[-26px]"
                :class="[isSafari ? 'gap-6' : 'gap-3',
                  locale === 'ar' ? 'left-[20px]' : 'left-[-20px]'
                ]"
              >
                <Download
                  v-if="isMac"
                  icon="desktop"
                  :label="$t('mac')"
                  :href="donwloadlinks.macOS"
                />
                <Download
                  v-if="isAndriod"
                  icon="andriod"
                  :label="$t('android')"
                  :href="donwloadlinks.andriod"
                />
                <Download
                  v-if="isApple"
                  icon="apple"
                  :label="$t('ios')"
                  :href="donwloadlinks.ios"
                />
              </div>
            </div>
          </div>
          <div class="w-full h-full relative">
            <div class="flex items-center md:justify-end xs:justify-center z-0">
              <img
                ref="imageRef"
                :src="'/Images/earth.webp'"
                alt="role of jernas"
                :width="imageWidth"
                :height="imageHeight"
                @load="handleImageLoad"
                lazy="true"
                :class="{ 'safari-image': isSafari }"
              />
              <div class="absolute z-[5] md:hidden xs:block">
                <div
                  class="w-full h-full flex flex-col items-center text-center md:p-0 sm:px-20 xs:p-0"
                >
                  <h1 class="xl:text-4xl xs:text-3xl font-bold">
                    {{ $t("hero_title") }}
                  </h1>
                  <h4 class="xl:text-xl md:text-xl xs:text-base font-semibold">
                    {{ $t("hero_subtitle") }}
                  </h4>
                </div>
              </div>
              <div class="absolute z-[5] bottom-0 mb-4 md:hidden xs:block">
                <div class="flex items-center justify-center">
                  <Button
                    v-if="userAgent === 'andriod'"
                    :title="$t('download_for_android')"
                    shape="transparent"
                    prefix
                    prefixIcon="white-andriod"
                    :href="donwloadlinks.andriod"
                    class="text-white !bg-[#6084E0] border !border-[#6084E0] h-12"
                  />
                  <Button
                    v-if="userAgent === 'apple'"
                    :title="$t('download_for_ios')"
                    shape="transparent"
                    prefix
                    prefixIcon="white-apple"
                    :href="donwloadlinks.ios"
                    class="text-white !bg-[#6084E0] border !border-[#6084E0] h-12"
                  />
                </div>
              </div>
              <div
                ref="sketchContainer"
                class="z-0 absolute sm:top-20 xs:top-10 lg:top-10 xl:top-16 bottom-0"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  ref,
  onMounted,
  nextTick,
  onUnmounted,
  inject,
  watchEffect,
} from "vue";
import { Download, Button } from "../reuse";
import p5 from "p5";
import { useI18n } from "vue-i18n";

export default {
  components: {
    Download,
    Button,
  },
  setup() {
    const { locale } = useI18n();

    const userAgent = inject("userAgent");
    const sketchContainer = ref(null);
    const imageRef = ref(null);
    const isSafari = ref(false);
    const imageWidth = ref(545);
    const imageHeight = ref(584);
    const donwloadlinks = inject("Download_links");
    const isApple = ref(false);
    const isMac = ref(false);
    const isAndriod = ref(false);
    let p5Instance = null;

    const detectSafari = () => {
      const ua = navigator.userAgent.toLowerCase();
      isSafari.value = ua.indexOf("safari") > -1 && ua.indexOf("chrome") === -1;
      if (isSafari.value) {
        imageWidth.value -= 80;
        imageHeight.value -= 100;
      }
    };

    const handleImageLoad = () => {
      if (p5Instance) {
        p5Instance.remove();
      }
      if (window.innerWidth < 768) {
        sketchContainer.value = null;
        return;
      }
      nextTick(() => {
        const imgWidth = imageRef.value
          ? imageRef.value?.clientWidth * 0.98
          : 0;
        const imgHeight = imageRef.value
          ? imageRef.value?.clientHeight * 0.98
          : 0;

        if (window.innerWidth >= 768 && !sketchContainer.value) {
          sketchContainer.value = document.createElement("div");
          document.body.appendChild(sketchContainer.value);
        }

        if (sketchContainer.value) {
          p5Instance = new p5((p) => {
            let constellation = [];
            let n;
            let d;
            let speedMultiplier = 1;

            p.setup = () => {
              const canvas = p
                .createCanvas(imgWidth, imgHeight)
                .parent(sketchContainer.value);
              p.pixelDensity(p.displayDensity());
              p.smooth();
              canvas.style("background", "transparent");

              canvas.mouseOver(() => {
                speedMultiplier = 1.5;
              });

              canvas.mouseOut(() => {
                speedMultiplier = 1;
              });

              n = 100;
              for (let i = 0; i <= n; i++) {
                constellation.push(new Star(p));
              }
              p.strokeWeight(0.75);
              p.stroke(255, 255, 255, 64);
            };

            p.draw = () => {
              p.clear();

              for (let i = 0; i < constellation.length; i++) {
                constellation[i].update(speedMultiplier);
                for (let j = 0; j < constellation.length; j++) {
                  if (i > j) {
                    d = constellation[i].loc.dist(constellation[j].loc);
                    if (d <= p.width / 8) {
                      p.line(
                        constellation[i].loc.x,
                        constellation[i].loc.y,
                        constellation[j].loc.x,
                        constellation[j].loc.y
                      );
                    }
                  }
                }
              }

              if (p.frameCount % 600 === 0) {
                for (let i = 0; i < constellation.length; i++) {
                  constellation[i].reset();
                }
              }
            };

            class Star {
              constructor(p) {
                this.p = p;
                this.reset();
              }

              reset() {
                this.a = this.p.random(5 * this.p.TAU);
                this.r = this.p.random(this.p.width * 0.4, this.p.width * 0.25);
                this.loc = this.p.createVector(
                  this.p.width / 2 + this.p.sin(this.a) * this.r,
                  this.p.height / 2 + this.p.cos(this.a) * this.r
                );
                this.speed = this.p.createVector();
                this.bam = this.p.createVector();
              }

              update(speedMultiplier) {
                this.bam = p5.Vector.random2D();
                this.bam.mult(0.45);
                this.speed.add(this.bam);

                this.m = this.p.constrain(
                  this.p.map(
                    this.p.noise(this.loc.x, this.loc.y),
                    0,
                    1,
                    0.05,
                    8
                  ),
                  0.05,
                  8
                );
                this.speed.normalize().mult(this.m * speedMultiplier);

                if (
                  this.p.dist(
                    this.loc.x,
                    this.loc.y,
                    this.p.width / 2,
                    this.p.height / 2
                  ) >
                  (this.p.width / 2) * 1
                ) {
                  if (this.loc.x < this.p.width / 2) {
                    this.loc.x = this.p.width - this.loc.x - 4;
                  } else if (this.loc.x > this.p.width / 2) {
                    this.loc.x = this.p.width - this.loc.x + 4;
                  }
                  if (this.loc.y < this.p.height / 2) {
                    this.loc.y = this.p.width - this.loc.y - 4;
                  } else if (this.loc.x > this.p.height / 2) {
                    this.loc.y = this.p.width - this.loc.y + 4;
                  }
                }
                this.loc = this.loc.add(this.speed);
              }
            }

            p.windowResized = () => {
              const newImgWidth = imageRef.value?.clientWidth / 0.9;
              const newImgHeight = imageRef.value?.clientHeight;
              p.resizeCanvas(newImgWidth, newImgHeight);
            };
          }, sketchContainer.value);
        }
      });
    };

    watchEffect(() => {
      const innerWidth = window.innerWidth;
      if (userAgent === "mac" && innerWidth < 1370) {
        isMac.value = true;
      } else if (userAgent === "apple" && innerWidth < 1370) {
        isApple.value = true;
      } else if (userAgent === "andriod" && innerWidth < 1370) {
        isAndriod.value = true;
      } else if (userAgent === "window" && innerWidth < 1370) {
        isAndriod.value = false;
      } else if (userAgent === "linux" && innerWidth < 1370) {
        isAndriod.value = false;
      } else {
        isApple.value = true;
        isAndriod.value = true;
        isMac.value = true;
      }
    });

    onMounted(() => {
      detectSafari();
      handleImageLoad();
      window.addEventListener("resize", handleImageLoad);
    });

    onUnmounted(() => {
      if (p5Instance) {
        p5Instance.remove();
        window.removeEventListener("resize", handleImageLoad);
        sketchContainer.value = null;
      }
    });

    return {
      sketchContainer,
      imageRef,
      userAgent,
      isSafari,
      imageWidth,
      imageHeight,
      donwloadlinks,
      isApple,
      isMac,
      isAndriod,
      locale
    };
  },
};
</script>

<style>
.hero-bg {
  background: radial-gradient(
    235.85% 182.44% at 101.51% 97.02%,
    #ecf2ff 0%,
    rgba(251, 252, 255, 0.9) 68.21%,
    rgba(198, 214, 255, 0.99) 100%
  );
}
</style>
