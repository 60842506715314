<template>
  <div class="w-full h-full" :class="[active ? 'bg-[#E6EDFF]' : '#fff']">
    <div class="container">
      <!-- prefixer -->
      <div class="flex md:justify-between items-center md:flex-nowrap xs:flex-wrap xs:justify-center w-full pt-12" v-if="prefix">
        <div class="md:text-start xs:text-center flex flex-col gap-3 lg:w-[40rem] md:p-0 sm:px-14 xs:w-full">
          <h2 class="text-3xl font-bold text-[#0B0B0B]">{{ title }}</h2>
          <span class="text-base font-normal text-[#7B7B7B]">{{ description }}</span>
        </div>
        <div class="md:p-0 xs:py-6">
          <img
            :src="`/Images/${image}.webp`"
            :alt="image"
            role="presentation"
            width="200"
            height="592"
            class="pointer-events-none"
            :class="[interact ? 'md:relative top-4' : '']"
          />
        </div>
      </div>
      <!-- suffix -->
      <div class="flex md:justify-between items-center xs:justify-center md:flex-nowrap xs:flex-wrap w-full pt-12 md:flex-row xs:flex-col-reverse" v-else>
        <div class="md:p-0 xs:py-6">
          <img
            :src="`/Images/${image}.webp`"
            :alt="image"
            role="presentation"
            width="200"
            height="592"
            :class="[interact ? 'md:relative top-4' : 'md:mb-12']"
            class="pointer-events-none"
          />
        </div>
        <div class="md:text-right xs:text-center flex flex-col gap-3 lg:w-[40rem] lg:p-0 md:px-4 tab:px-10 sm:px-14 xs:w-full">
          <h2 class="text-3xl font-bold text-[#0B0B0B]">{{ title }}</h2>
          <span class="text-base font-normal text-[#7B7B7B]">{{ description }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";

const { locale } = useI18n();
const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: "title",
    required: true,
  },
  description: {
    type: String,
    default: "description",
    required: true,
  },
  prefix: {
    type: Boolean,
    default: false,
  },
  image: {
    type: String,
    default: "",
    required: true,
  },
  interact: {
    type: Boolean,
    default: false,
  },
});
</script>

<style>
</style>
