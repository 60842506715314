<template>
  <div class="w-full h-auto">
    <Tablet :isShow="isShow" v-if="isShow" />
    <Hero v-else />
    <CardBanner :userAgent="userAgent" />
  </div>
</template>

<script setup>
import Hero from "@/components/Home/Hero.vue";
import Tablet from "@/components/Home/Tablet.vue";
import CardBanner from "@/components/Home/CardBanner.vue";
import { inject, ref, onMounted, onUnmounted } from "vue";

const isShow = ref(false);

const userAgent = inject("userAgent");

const updateIsShow = () => {
  const innerWidth = window.innerWidth;
  isShow.value = innerWidth <= 1024;
};

onMounted(() => {
  updateIsShow();
  window.addEventListener("resize", updateIsShow);
});

onUnmounted(() => {
  window.removeEventListener("resize", updateIsShow);
});
</script>

<style>
</style>
