<template>
  <div class="relative z-0 w-full image-container">
    <div class="w-full h-full relative">
      <img
        :src="`/Images/${image}.webp`"
        :alt="`${image}-card`"
        role="card-image"
        class="relative z-0 base-image"
      />
      <img
        :src="`/Images/${overlay}.webp`"
        :alt="`${overlay}-card`"
        role="overlay-image"
        class="absolute z-1"
        :class="overlayClass"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from "vue";

const props = defineProps({
  image: {
    type: String,
    required: true,
  },
  overlay: {
    type: String,
    required: true,
  },
  position: {
    type: String,
    default: "",
  },
});

const screenWidth = ref(window.innerWidth);

const updateScreenWidth = () => {
  screenWidth.value = window.innerWidth;
};

onMounted(() => {
  window.addEventListener("resize", updateScreenWidth);
});

onUnmounted(() => {
  window.removeEventListener("resize", updateScreenWidth);
});

const useLowResImage = computed(() => screenWidth.value < 768);
const useLowResOverlay = computed(() => screenWidth.value < 768);

const overlayClass = computed(() => {
  return [
    props.position === "top"
      ? "bottom-0 md:w-full overlay-image"
      : "top-[-1rem] md:!w-[240px] sm:!w-[140px] xs:!w-[200px] m-auto left-0 right-0 mt-2 privacy-overlay-image",
  ];
});
</script>

<style>
.image-container {
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  width: 100%;
}

.base-image {
  display: block;
  width: 100%;
  height: auto;
}

.overlay-image {
  display: block;
  width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.privacy-overlay-image {
  display: block;
  width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out;
}
.wrapper_img {
  width: 100%;
}

@media (max-width: 1024px) {
  .overlay-image {
    width: 100% !important;
  }
  .privacy-overlay-image {
    width: 75% !important;
  }
}

@media (max-width: 768px) {
  .overlay-image {
    width: 100% !important;
  }
  .privacy-overlay-image {
    width: 80% !important;
  }
}

@media (max-width: 400px) {
  .overlay-image {
    width: 100% !important;
  }
  .privacy-overlay-image {
    width: 80% !important;
  }
}

@media (orientation: landscape) {
  .image-container {
    width: 100%;
  }
  .base-image,
  .overlay-image,
  .wrapper_img {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .image-container:hover .overlay-image {
    transform: scale(1.02);
  }
  .image-container:hover .privacy-overlay-image {
    transform: scale(1.02);
  }
}
</style>
