<template>
  <section class="hero-bg">
    <div class="w-full h-full">
      <div class="container">
        <!-- for tablet only  -->
        <div class="w-full" :class="[isShow ? '' : 'only_for_tablets']">
          <div class="flex items-center justify-center">
            <div class="w-full h-full">
              <div class="flex items-center justify-center mt-4">
                <img
                  src="/Images/earth_bg.webp"
                  alt="role of jernas"
                  :width="imageWidth"
                  :height="imageHeight"
                  @load="handleImageLoad"
                  lazy="true"
                  :class="{ 'safari-image': isSafari }"
                />
                <div class="absolute z-[5] title-container">
                  <div
                    class="w-full h-full flex flex-col items-center text-center gap-4 sm:px-20 xs:px-8"
                  >
                    <h1
                      class="md:text-[3rem] xs:text-4xl font-bold md:my-2 xs:m-0"
                    >
                      {{ $t("hero_title") }}
                    </h1>
                    <h4
                      class="xl:text-xl md:text-2xl xs:text-sm font-[300] text-[#4B4B4B]"
                    >
                      {{ $t("hero_subtitle") }}
                    </h4>
                  </div>
                </div>
              </div>
              <div class="my-10">
                <div class="flex items-center justify-center relative z-10">
                  <Button
                    v-if="userAgent == 'apple' || userAgent == 'mac'"
                    :title="$t('download_for_ios')"
                    shape="transparent"
                    prefix
                    prefixIcon="white-apple"
                    :href="donwloadlinks.ios"
                    class="!text-white !bg-[#6084E0] border !border-[#6084E0] h-12 w-[225px]"
                  />
                  <Button
                    v-if="userAgent == 'android'"
                    :title="$t('download_for_android')"
                    shape="transparent"
                    prefix
                    prefixIcon="white-andriod"
                    :href="donwloadlinks.andriod"
                    class="!text-white !bg-[#6084E0] border !border-[#6084E0] h-12"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, onMounted, nextTick, onUnmounted, inject } from "vue";
import { Download, Button } from "../reuse";
import p5 from "p5";

export default {
  components: {
    Download,
    Button,
  },
  props: {
    isShow: {
      type: Boolean,
    },
  },
  setup() {
    const userAgent = inject("userAgent");
    const isSafari = ref(false);
    const imageWidth = ref(545);
    const imageHeight = ref(500);
    const donwloadlinks = inject("Download_links");
    let p5Instance = null;

    const detectSafari = () => {
      const ua = navigator.userAgent.toLowerCase();
      isSafari.value = ua.indexOf("safari") > -1 && ua.indexOf("chrome") === -1;
      if (isSafari.value) {
        imageWidth.value -= 80;
        imageHeight.value -= 100;
      }
    };

    onMounted(() => {
      detectSafari();
    });

    return {
      userAgent,
      isSafari,
      imageWidth,
      imageHeight,
      donwloadlinks,
    };
  },
};
</script>

<style>
.hero-bg {
  background: radial-gradient(
    235.85% 182.44% at 101.51% 97.02%,
    #ecf2ff 0%,
    rgba(251, 252, 255, 0.9) 68.21%,
    rgba(198, 214, 255, 0.99) 100%
  );
}

.only_for_tablets {
  display: none;
}

/* iPad Pro */
@media only screen and (device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) {
  .not_for_tablets {
    display: none;
  }
  .only_for_tablets {
    display: block;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .not_for_tablets {
    display: none;
  }
  .only_for_tablets {
    display: block;
  }
}
@media only screen and (device-width: 853px) and (device-height: 1280px) {
  .not_for_tablets {
    display: none;
  }
  .only_for_tablets {
    display: block;
  }
}

@media only screen and (max-device-width: 400px) and (-webkit-min-device-pixel-ratio: 2) {
  .title-container div {
    padding: 7rem 1.5rem;
  }
}

@media only screen and (max-device-width: 360px) and (-webkit-min-device-pixel-ratio: 2) {
  .title-container {
    top: 2rem;
  }
}

@media only screen and (max-device-width: 334px) and (-webkit-min-device-pixel-ratio: 2) {
  .title-container {
    top: 2rem;
  }
  .title-container div {
    padding: 0;
  }
}
</style>
