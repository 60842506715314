<template>
  <div ref="dropdownContainer" class="relative">
    <div
      class="flex items-center justify-center gap-2 border border-[#D9D9D9] rounded-3xl w-[80px] cursor-pointer"
      @click.prevent="openDropdown"
    >
      {{ label }}
      <img
        src="/Icons/dropdown-icon.webp"
        alt="dropdown-icon"
        role="open dropdown"
        class="pointer-events-none"
        width="10"
      />
    </div>
    <div
      class="w-[208px] h-[110px] absolute top-0 bg-white mt-[3.5rem] z-[100] shadow-[0px_0px_40px_0px_rgba(0,0,0,0.10)] rounded-[10px]"
      :class="[locale === 'ar' ? 'left-0' : 'right-0']"
      v-if="isopen"
    >
      <div class="w-full h-full my-2 text-black">
        <span
          class="px-4 py-3 flex items-center justify-between hover:bg-[#F5F5F5] cursor-pointer hover:text-[#6084E0]"
          v-for="(item, index) in Options"
          :key="index"
          :class="[
            label === item.language ? 'text-[#6084E0] bg-[#F5F5F5]' : '',
            locale === 'ar' ? 'flex-row-reverse' : '',
          ]"
          @click.prevent="selectedLangauge(item.language)"
        >
          {{ item.language }}
          <img
            v-if="label === item.language"
            src="/Icons/Done.webp"
            alt="check-icon"
            role="selection"
            width="15"
            height="15"
            class="pointer-events-none"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from "vue";
import { useI18n } from "vue-i18n";

const emit = defineEmits(["selected"]);
const { locale } = useI18n();
defineProps({
  label: {
    type: String,
    default: "",
  },
  Options: {
    type: Array,
    default: () => [],
  }
});

const isopen = ref(false);
const dropdownContainer = ref(null);

const openDropdown = () => {
  isopen.value = !isopen.value;
};

const selectedLangauge = (lang) => {
  emit("selected", lang);
  isopen.value = false;
};

const handleDomClick = (event) => {
  if (!dropdownContainer.value.contains(event.target)) {
    isopen.value = false;
  }
};

onMounted(() => {
  document.addEventListener("click", handleDomClick);
});

onUnmounted(() => {
  document.removeEventListener("click", handleDomClick);
});
</script>

<style>
</style>