<template>
  <section class="flex flex-col justify-center">
    <div class="container tab:block xs:hidden md:pt-10">
      <div
        class="flex items-center gap-6 my-10 justify-center tab:mx-4 xs:m-0"
        :class="[
          userAgent === 'apple' || userAgent === 'mac'
            ? 'flex-wrap xl:flex-nowrap ipad_class'
            : '',
          userAgent === 'window' ? 'flex-wrap xl:flex-nowrap' : '',
          userAgent === 'andriod' ? 'md:flex-wrap xl:flex-nowrap' : '',
        ]"
      >
        <Card
          :image="locale == 'ar' ? 'woman_ar' : 'woman_bg'"
          overlay="speak"
          position="top"
        />
        <Card
          :image="locale == 'ar' ? 'privacy_ar' : 'privacy_bg'"
          overlay="privacy"
        />
        <Card
          :image="locale == 'ar' ? 'men_ar' : 'men_bg'"
          position="top"
          overlay="stay"
        />
      </div>
    </div>
    <!-- for small screen  -->
    <div class="my-6">
      <Slider :cards="cards" />
    </div>

    <div
      class="w-full flex items-center justify-center md:mt-0 md:mb-14 xs:my-12"
    >
      <RouterLink to="/feature">
        <Button
          :title="$t('explore_features')"
          suffix
          raduis="rounded"
          :locale="locale"
        />
      </RouterLink>
    </div>
  </section>
</template>

<script setup>
import { inject, onMounted, ref, watchEffect } from "vue";
import { Card, Button, Slider } from "../reuse";
import { useI18n } from "vue-i18n";

const userAgent = inject("userAgent");

const { t, locale } = useI18n();

defineProps({
  userAgent: {
    type: String,
    default: "",
  },
});

const cards = ref();

watchEffect(() => {
  if (locale.value == "ar") {
    cards.value = [
      { id: 1, image: "privacy_ar", overlay: "privacy" },
      { id: 2, image: "men_ar", position: "top", overlay: "stay" },
      { id: 3, image: "woman_ar", overlay: "speak", position: "top" },
      { id: 4, image: "privacy_ar", overlay: "privacy" },
      { id: 5, image: "men_ar", position: "top", overlay: "stay" },
      { id: 6, image: "woman_ar", overlay: "speak", position: "top" },
    ];
  } else {
    cards.value = [
      { id: 1, image: "privacy_bg", overlay: "privacy" },
      { id: 2, image: "men_bg", position: "top", overlay: "stay" },
      { id: 3, image: "woman_bg", overlay: "speak", position: "top" },
      { id: 4, image: "privacy_bg", overlay: "privacy" },
      { id: 5, image: "men_bg", position: "top", overlay: "stay" },
      { id: 6, image: "woman_bg", overlay: "speak", position: "top" },
    ];
  }
});

onMounted(() => {
  if (locale.value == "ar") {
    cards.value = [
      { id: 1, image: "privacy_ar", overlay: "privacy" },
      { id: 2, image: "men_ar", position: "top", overlay: "stay" },
      { id: 3, image: "woman_ar", overlay: "speak", position: "top" },
      { id: 4, image: "privacy_ar", overlay: "privacy" },
      { id: 5, image: "men_ar", position: "top", overlay: "stay" },
      { id: 6, image: "woman_ar", overlay: "speak", position: "top" },
    ];
  } else {
    cards.value = [
      { id: 1, image: "privacy_bg", overlay: "privacy" },
      { id: 2, image: "men_bg", position: "top", overlay: "stay" },
      { id: 3, image: "woman_bg", overlay: "speak", position: "top" },
      { id: 4, image: "privacy_bg", overlay: "privacy" },
      { id: 5, image: "men_bg", position: "top", overlay: "stay" },
      { id: 6, image: "woman_bg", overlay: "speak", position: "top" },
    ];
  }
});
</script>

<style>
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .ipad_class {
    flex-wrap: nowrap !important;
  }
}
</style>
