<template>
  <div class="loading-container">
    <img src="/loading_gif.gif" alt="Loading..." class="loading-gif" />
  </div>
</template>

<script setup>
</script>

<style>
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #f9f9f9;
}

.loading-gif {
  width: 100px;
  height: 100px;
  max-width: 100%;
  max-height: 100%;
}
</style>
