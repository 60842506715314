<template>
  <div class="security-container">
    <div class="w-full h-full">
      <article class="md:px-10 md:py-10 sm:px-4 sm:pt-10 xs:pt-5 xs:px-0">
        <h1
          class="text-[#0B0B0B] md:text-3xl sm:text-lg xs:text-lg font-bold text-center break-word"
        >
          {{ $t("privacy") }}
        </h1>

        <div
          class="flex flex-col gap-2 md:my-10 md:mx-16 sm:my-8 sm:mx-10 xs:my-6 xs:mx-6"
        >
          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t("date") }}
          </p>
          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t("policy") }}
          </p>
          <p
            class=" md:text-xl sm:text-base xs:text-[15px] break-word font-bold text-[#7B7B7B]"
          >
            {{ $t("interpretation") }}
          </p>

          <p
            class=" md:text-base sm:text-base xs:text-[15px] break-word font-bold text-[#7B7B7B]"
          >
            {{ $t("int") }}
          </p>

          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t("privacy_word") }}
          </p>
          <p
            class=" md:text-xl sm:text-base xs:text-[15px] break-word font-bold text-[#7B7B7B]"
          >
            {{ $t("privacy_defi") }}
          </p>
          <p
            class=" md:text-baseß sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t("privacy_purpose") }}
          </p>
          <ul
            class="text-[#7B7B7B] text-base font-normal px-6 flex flex-col gap-2"
          >
            <li class="list-disc md:text-base xs:text-[15px] break-word">
              {{ $t("privacy_acc") }}
            </li>
            <li class="list-disc md:text-base xs:text-[15px] break-word">
              {{ $t("privacy_affliates") }}
            </li>
            <li class="list-disc md:text-base xs:text-[15px] break-word">
              {{ $t("privacy_app") }}
            </li>
            <li class="list-disc md:text-base xs:text-[15px] break-word">
              {{ $t("privacy_refer") }}
            </li>
            <li class="list-disc md:text-base xs:text-[15px] break-word">
              {{ $t("privacy_uae") }}
            </li>
            <li class="list-disc md:text-base xs:text-[15px] break-word">
              {{ $t("privacy_device") }}
            </li>
            <li class="list-disc md:text-base xs:text-[15px] break-word">
              {{ $t("privacy_personal") }}
            </li>
            <li class="list-disc md:text-base xs:text-[15px] break-word">
              {{ $t("privacy_service") }}.
            </li>
            <li class="list-disc md:text-base xs:text-[15px] break-word">
              {{ $t("privacy_provider") }}
            </li>
            <li class="list-disc md:text-base xs:text-[15px] break-word">
              {{ $t("privacy_using") }}
            </li>
            <li class="list-disc md:text-base xs:text-[15px] break-word">
              {{ $t("privacy_mean") }}
            </li>
          </ul>

          <p
            class=" md:text-xl sm:text-base xs:text-[15px] break-word font-bold text-[#7B7B7B]"
          >
            {{ $t("privacy_e2ee_title") }}
          </p>
          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t("privacy_e2ee_content_1") }}
          </p>
          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t("privacy_e2ee_content_2") }}
          </p>
          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t("privacy_e2ee_content_3") }}
          </p>

          <p
            class=" md:text-xl sm:text-base xs:text-[15px] break-word font-bold text-[#7B7B7B]"
          >
            {{ $t("privacy_personal_use") }}
          </p>

          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t("privacy_type") }}
          </p>
          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t("privacy_man") }}
          </p>

          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t("privacy_user") }}
          </p>

          <ul
            class="text-[#7B7B7B] text-base font-normal px-6 flex flex-col gap-2"
          >
            <li class="list-disc md:text-base xs:text-[15px] break-word">
              {{ $t("privacy_phone") }}
            </li>
            <li class="list-disc md:text-base xs:text-[15px] break-word">
              {{ $t("privacy_data") }}
            </li>
          </ul>
          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t("privacy_collect") }}
          </p>

          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t("usage_data_description") }}
          </p>

          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t("mobile_device_access") }}
          </p>
          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t("browser_data_collection") }}
          </p>

          <p
            class=" md:text-xl sm:text-base xs:text-[15px] break-word font-bold text-[#7B7B7B]"
          >
            {{ $t("information_collected_application") }}
          </p>

          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t("application_features_collection") }}
          </p>
          <ul
            class="text-[#7B7B7B] text-base font-normal px-6 flex flex-col gap-2"
          >
            <li class="list-disc md:text-base xs:text-[15px] break-word">
              {{ $t("application_features_list_1") }}
            </li>
            <!-- <li class="list-disc md:text-base xs:text-[15px] break-word">
              {{ $t("application_features_list_2") }}
            </li> -->
            <li class="list-disc md:text-base xs:text-[15px] break-word">
              {{ $t("application_features_list_3") }}
            </li>
          </ul>
          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t("application_data_usage") }}
          </p>

          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t("data_access_settings") }}
          </p>

          <p
            class=" md:text-xl sm:text-base xs:text-[15px] break-word font-bold text-[#7B7B7B]"
          >
            {{ $t("personal_data_usage") }}
          </p>

          <ul
            class="text-[#7B7B7B] text-base font-normal px-6 flex flex-col gap-2"
          >
            <li class="list-disc md:text-base xs:text-[15px] break-word">
              {{ $t("personal_data_usage_list_1") }}
            </li>
            <li class="list-disc md:text-base xs:text-[15px] break-word">
              {{ $t("personal_data_usage_list_2") }}
            </li>
            <li class="list-disc md:text-base xs:text-[15px] break-word">
              {{ $t("personal_data_usage_list_3") }}
            </li>
            <li class="list-disc md:text-base xs:text-[15px] break-word">
              {{ $t("personal_data_usage_list_4") }}
            </li>
            <li class="list-disc md:text-base xs:text-[15px] break-word">
              {{ $t("personal_data_usage_list_5") }}
            </li>
            <li class="list-disc md:text-base xs:text-[15px] break-word">
              {{ $t("personal_data_usage_list_6") }}
            </li>
            <li class="list-disc md:text-base xs:text-[15px] break-word">
              {{ $t("personal_data_usage_list_7") }}
            </li>
          </ul>

          <p
            class=" md:text-base sm:text-base xs:text-[15px] break-word font-bold text-[#7B7B7B]"
          >
            {{ $t("personal_data_sharing") }}
          </p>

          <ul
            class="text-[#7B7B7B] text-base font-normal px-6 flex flex-col gap-2"
          >
            <li class="list-disc md:text-base xs:text-[15px] break-word">
              {{ $t("personal_data_sharing_list_1") }}
            </li>
            <li class="list-disc md:text-base xs:text-[15px] break-word">
              {{ $t("personal_data_sharing_list_2") }}
            </li>
            <li class="list-disc md:text-base xs:text-[15px] break-word">
              {{ $t("personal_data_sharing_list_3") }}
            </li>
            <li class="list-disc md:text-base xs:text-[15px] break-word">
              {{ $t("personal_data_sharing_list_4") }}
            </li>
            <li class="list-disc md:text-base xs:text-[15px] break-word">
              {{ $t("personal_data_sharing_list_5") }}
            </li>
            <li class="list-disc md:text-base xs:text-[15px] break-word">
              {{ $t("personal_data_sharing_list_6") }}
            </li>
          </ul>

          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t("data_retention_policy_personal") }}
          </p>

          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t("data_retention_policy_usage") }}
          </p>

          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t("personal_data_transfer") }}
          </p>

          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t("personal_data_transfer_description") }}
          </p>

          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t("personal_data_transfer_consent") }}
          </p>

          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t("personal_data_transfer_security") }}
          </p>
          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t("personal_data_disclosure") }}
          </p>

          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t("business_transactions") }}
          </p>

          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t("business_transactions_description") }}
          </p>
          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t("law_enforcement") }}
          </p>

          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t("law_enforcement_description") }}
          </p>

          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t("legal_requirements") }}
          </p>

          <ul
            class="text-[#7B7B7B] text-base font-normal px-6 flex flex-col gap-2"
          >
            <li class="list-disc md:text-base xs:text-[15px] break-word">
              {{ $t("legal_requirements_list_2") }}
            </li>
            <li class="list-disc md:text-base xs:text-[15px] break-word">
              {{ $t("legal_requirements_list_3") }}
            </li>
            <li class="list-disc md:text-base xs:text-[15px] break-word">
              {{ $t("legal_requirements_list_4") }}
            </li>
            <li class="list-disc md:text-base xs:text-[15px] break-word">
              {{ $t("legal_requirements_list_5") }}
            </li>
          </ul>

          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
          {{ $t('security_personal_data') }}
          </p>
          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
          {{ $t('children_privacy_policy') }}
          </p>

          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t('children_privacy') }}
          </p>
          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t('children_privacy_policy_description') }}
          </p>
          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t('children_privacy_consent') }}
          </p>
          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t('links_other_websites') }}
          </p>
          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t('links_other_websites_description') }}
          </p>
          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t('links_other_websites_disclaimer') }}
          </p>

          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t('changes_privacy_policy') }}
          </p>
          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{$t('changes_privacy_policy_description')}}
          </p>
          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t('notify_changes') }}
          </p>
          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t('review_privacy_policy') }}
          </p>
          <p
            class=" md:text-base sm:text-base xs:text-[15px] break-word font-bold text-[#7B7B7B]"
          >
            {{ $t("rights_more_information") }}
          </p>
          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t("following_preotection_law") }}
          </p>
          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t("copy_personal_store") }}
          </p>
          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t("right_ask_collect") }}
          </p>
          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t("right_restrict_processing") }}
          </p>
          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t("right_transfer_processing") }}
          </p>
          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t("relating_through_email") }}
          </p>
          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t('contact_us') }}
          </p>
          <p
            class=" md:text-base sm:text-sm xs:text-[15px] break-word font-normal text-[#7B7B7B]"
          >
            {{ $t('contact_us_description') }}
          </p>
          <ul class="text-[#7B7B7B] text-base font-normal px-6 flex flex-col gap-2">
            <li class="list-disc md:text-base xs:text-[15px] break-word">{{ $t('contact_us_by_email') }}help@jernas.io</li>
          </ul>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>