<template>
  <div class="w-full h-auto flex flex-col justify-center md:pb-14 xs:pb-0">
    <DownloadTablet :isShow="isShow" v-if="isShow" />
    <Download :isShow="isShow" v-else />
  </div>
</template>

<script setup>
import Download from "@/components/Downlad/index.vue";
import DownloadTablet from "@/components/Downlad/tabletDownload.vue";
import { inject, onMounted, onUnmounted, ref, watchEffect } from "vue";

const userAgent = inject("userAgent");
const isShow = ref(false);

const updateIsShow = () => {
  const innerWidth = window.innerWidth;
  if (
    (userAgent === "mac" && innerWidth <= 1024) ||
    (userAgent === "apple" && innerWidth <= 1024) ||
    (userAgent === "android" && innerWidth <= 1024) ||
    (userAgent === "window" && innerWidth <= 1024)
  ) {
    isShow.value = true;
  } else {
    isShow.value = false;
  }
};

onMounted(() => {
  updateIsShow();
  window.addEventListener("resize", updateIsShow);
});

onUnmounted(() => {
  window.removeEventListener("resize", updateIsShow);
});
</script>

<style> 
</style>

