<template>
  <teleport to="body">
    <transition name="fade">
      <div v-if="showDialog" class="dialog-overlay md:hidden" @click="handleOverlayClick">
        <div class="dialog" @click.stop>
          <slot name="content"></slot>
        </div>
      </div>
    </transition>
  </teleport>
</template>
  
<script setup>
import { onMounted, onUnmounted } from 'vue'

const emits = defineEmits(['close'])
const props = defineProps({
  showDialog: {
    type: Boolean,
    default: false
  }
})

const handleOverlayClick = () => {
  emits('close')
}

onMounted(() => {
  if (props.showDialog === true) {
    document.addEventListener('click', handleOverlayClick)
  }
})

onUnmounted(() => {
  if (props.showDialog === true) {
    document.removeEventListener('click', handleOverlayClick)
  }
})
</script>
  
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999 !important;
}
.dialog {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: 250px;
  height: 120px;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
